import {createMuiTheme} from "@material-ui/core/styles";

const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#bdbb47',
            main: '#bdbb47',
            dark: '#bdbb47',
            contrastText: '#000'
        }
    }
});

export  default theme;