import React, {useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@material-ui/core";
import './style/login-dialog.scss';
import ajaxUtil from "../util/ajaxUtil";

interface Props {
    loggedIn: boolean,
    login: () => void;
}

interface User {
    username: string,
    password: string;
}

interface SuccessModel {
    ok: boolean
}

const LoginDialog = (props: Props) => {
    const [user, setUser] = useState<User>({
        username: '',
        password: ''
    })

    const [invalidLogin, setInvalidLogin] = useState<boolean>(false);

    const loginClick = () => {
        ajaxUtil.post<SuccessModel>('/api/user/login', user).then(response => {
            if (response.ok) {
                setInvalidLogin(false);
                setUser({
                    username: '',
                    password: ''
                })
                props.login();

            } else {
                setInvalidLogin(true);
            }
        })
    }

    return !props.loggedIn ? <Dialog open={true}>
        <DialogTitle> Login </DialogTitle>
        <DialogContent>
            <TextField error={invalidLogin} label={'AD username'} autoFocus name={'username'} value={user.username}
                       onChange={e => {
                           setUser({
                               ...user,
                               username: e.target.value
                           })
                       }}/>
            <TextField error={invalidLogin} label={'Password'} name={'password'} value={user.password} type={'password'}
                       helperText={invalidLogin ? 'Invalid login' : ''}
                       onChange={e => {
                           setUser({
                               ...user,
                               password: e.target.value
                           })
                       }}/>
            {invalidLogin ? null : null}
        </DialogContent>
        <DialogActions>
            <Button onClick={loginClick}>Login</Button>
        </DialogActions>
    </Dialog> : null

}
export default LoginDialog;