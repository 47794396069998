const Get = 'get';
const Put = 'put';
const Post = 'post';
const Delete = 'delete';

const Success = 200;
const SuccessNoContent = 204;
const BadRequest = 400;
const Unauthorized = 401;
const Forbidden = 403;
const NotFound = 404;
const MethodNotAllowed = 405;
const NotAcceptable = 406;
const PreconditionFailed = 412;
const UnsupportedMediaType = 415;
const InternalServerError = 500;
const NotImplemented = 501;

interface RequestOptions {
    showLoading?: boolean;
    redirectWhenUnauthorized?: boolean;
}

const defaultOptions: RequestOptions = {
    showLoading: true,
    redirectWhenUnauthorized: true,
};
const ajaxUtil = {
    get: <T = object>(url: string, options?: RequestOptions): Promise<T> => {
        options = { ...defaultOptions, ...options };
        return ajaxUtil.request(url, Get, null, options);
    },
    put: <T = object>(url: string, data: object, options?: RequestOptions): Promise<T> => {
        options = { ...defaultOptions, ...options };
        return ajaxUtil.request(url, Put, data, options);
    },
    post: <T = object>(url: string, data: object, options?: RequestOptions): Promise<T> => {
        options = { ...defaultOptions, ...options };
        return ajaxUtil.request(url, Post, data, options);
    },
    delete: <T = object>(url: string, options?: RequestOptions): Promise<T> => {
        options = { ...defaultOptions, ...options };
        return ajaxUtil.request(url, Delete, null, options);
    },
    request: <T = object>(url: string, method: string, data: object | null, options: RequestOptions): Promise<T> => {
        return new Promise<T>((success, fail) => {

            fetch(url, {
                method: method,
                body: data ? JSON.stringify(data) : null,
                headers: new Headers({
                    'Content-Type': 'application/json',
                }),
            })
                .then((data) => {
                    const resultStatus = data.status;

                    if (resultStatus >= Success && resultStatus < BadRequest) {
                        if (resultStatus === SuccessNoContent) {
                            //for HTTP 204 No Content success
                            success(null);
                        } else {
                            data.json()
                                .then((json) => success(json))
                                .catch(() => {
                                    success(null);
                                });
                        }
                    } else if (resultStatus === Unauthorized) {
                        if (options.redirectWhenUnauthorized) {
                            window.location.href = '/unauthorized';
                        }
                        fail('Unauthorized');
                    } else if (resultStatus === Forbidden) {
                        fail('Forbidden');
                    } else if (resultStatus === NotFound) {
                        fail('Not found');
                    } else if (resultStatus === MethodNotAllowed) {
                        fail('Method not allowed');
                    } else if (resultStatus === NotAcceptable) {
                        fail('Not acceptable');
                    } else if (resultStatus === PreconditionFailed) {
                        fail('Precondition failed');
                    } else if (resultStatus === UnsupportedMediaType) {
                        fail('Unsupported media type');
                    } else if (resultStatus >= BadRequest && resultStatus < InternalServerError) {
                        fail('Bad request');
                    } else if (resultStatus === NotImplemented) {
                        fail('Not implemented');
                    } else if (resultStatus >= InternalServerError) {
                        fail('Internal server error');
                    }
                })
                .catch((reason) => {
                    fail(reason);
                })
                .finally(() => {

                });
        });
    },
};

export default ajaxUtil;
