import {AppBar, Button, Toolbar, Typography} from "@material-ui/core";
import React from "react";
import './style/main-toolbar.scss';

interface Props {
    loggedIn: boolean,
    logout: () => void
}

const MainToolbar = (props: Props) => {
    return <AppBar position={"fixed"} color={'primary'}>
        <Toolbar className={'main-toolbar'}>
            <img src={'/logo.png'} alt={'logo'}/>
            <Typography variant="h6">
                NetRom - Power on
            </Typography>
            {props.loggedIn ? <Button onClick={props.logout}>Logout</Button> : null}
        </Toolbar>
    </AppBar>
}

export default MainToolbar;