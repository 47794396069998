import React, {useEffect, useState} from 'react';
import ajaxUtil from "./util/ajaxUtil";
import {ThemeProvider} from '@material-ui/core/styles';
import theme from "./util/MainTheme";
import MainToolbar from "./components/MainToolbar";
import LoginDialog from "./components/LoginDialog";
import PowerOnForm from "./components/PowerOnForm";


const App = () => {
    const [loggedIn, setLoggedIn] = useState<boolean>(false);
    useEffect(() => {
        ajaxUtil.get<boolean>('/api/user/get').then(ok => {
            setLoggedIn(ok);
        })
    }, []);

    const logoutHandler = () => {
        ajaxUtil.get('/api/user/logout').then(() => {
            setLoggedIn(false);
        });
    }

    const loginHandler = () => {
        setLoggedIn(true)
    }

    return (
        <ThemeProvider theme={theme}>
            <MainToolbar loggedIn={loggedIn} logout={logoutHandler}/>
            <LoginDialog loggedIn={loggedIn} login={loginHandler}/>
            <PowerOnForm loggedIn={loggedIn}/>
        </ThemeProvider>
    );
}
export default App;
