import React, {useEffect, useState} from "react";
import {Button, Paper, TextField} from "@material-ui/core";
import './style/login-dialog.scss';
import ajaxUtil from "../util/ajaxUtil";
import './style/power-on-form.scss';

interface Props {
    loggedIn: boolean,
}

interface PowerOnModel {
    mac: string
}

interface SuccessModel {
    ok: boolean
}

const PowerOnForm = (props: Props) => {
    const [model, setModel] = useState<PowerOnModel>({mac: ''});
    const [error, setError] = useState(false)
    const [sendCount, setSendCount] = useState<number>(0)

    useEffect(() => {
        if (props.loggedIn) {
            setModel({
                ...model,
                mac: localStorage.getItem('mac')
            })
        }
    }, [props.loggedIn])

    const turnOn = () => {
        localStorage.setItem('mac', model.mac);
        ajaxUtil.post<SuccessModel>('/api/powerOn', model).then((response) => {
            setError(!response.ok);
            if (response.ok) {
                setSendCount(sendCount + 1);

            } else {
                setSendCount(0);
            }
        })
    }

    return props.loggedIn ?
        <Paper className={'power-on-form'}>
            <TextField error={error} autoFocus name={'mac'} value={model.mac} label={'MAC address'}
                       helperText={error ? 'Invalid address' : sendCount ? `Send ${sendCount} package` : ''}
                       onChange={(e) => {
                           setModel(({
                               ...model,
                               mac: e.target.value
                           }))
                       }}/>
            <Button onClick={() => {
                turnOn();
            }}>Turn on</Button>
        </Paper>
        : null

}
export default PowerOnForm;